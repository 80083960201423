import { PAGE_SIZE, STATUS } from "@/utils/constants";
import { viewFileFromServer } from "@/utils/functions";
import axios from "axios";
import PaginationData from "./../../general/PaginationData";
import ContractForm from "./../../settings/settingsSalary/contractForms/ContractForm";
import EmployeeWorkContractImg from "@/assets/images/contracts.svg";
import EmployeeWorkContractsFilter from "./EmployeeWorkContractsFilter";

export default class EmployeeWorkContract {
  defaultImg = EmployeeWorkContractImg;
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.employeeWorkContractToken = "";
    this.employeeWorkContractCode = "";
    this.employeeWorkContractNameCurrent = "";
    this.employeeWorkContractNameAr = "";
    this.employeeWorkContractNameEn = "";
    this.employeeWorkContractNameUnd = "";
    this.contractFormToken = "";
    this.contractFormData = new ContractForm();
    this.employeeJobToken = "";
    this.employeeJobNameCurrent = "";
    this.employeeSectorToken = "";
    this.employeeSectorNameCurrent = "";
    this.employeeBranchToken = "";
    this.employeeBranchNameCurrent = "";
    this.employeeToken = "";
    this.employeeCode = "";
    this.employeeNameCurrent = "";
    this.employeeImagePath = "";
    this.employeeMakeActionCode = "";
    this.employeeMakeActionToken = "";
    this.employeeMakeActionNameCurrent = "";
    this.employeeMakeActionImagePath = "";
    this.employeeMakeActionNationalNumber = "";
    this.employeeMakeActionNationalNumberIssuingAuthorityCurrent = "";
    this.employeeMakeActionIssuanceDateNationalNumber = "";
    this.employeeMakeActionExpiryDateNationalNumber = "";
    this.employeeMakeActionPassportNumber = "";
    this.employeeMakeActionPassportNumberIssuingAuthorityCurrent = "";
    this.employeeMakeActionIssuanceDatePassportNumber = "";
    this.employeeMakeActionExpiryDatePassportNumber = "";
    this.employeeWorkContractStartDateTime = "";
    this.employeeWorkContractStartDate = "";
    this.employeeWorkContractStartTime = "";
    this.employeeWorkContractEndDateTime = "";
    this.employeeWorkContractEndDate = "";
    this.employeeWorkContractEndTime = "";
    this.employeeWorkContractDuration = "";
    this.employeeWorkContractSalary = "";
    this.employeeWorkContractSalaryWithCarrncy = "";
    this.employeeWorkContractSalaryText = "";
    this.employeeWorkContractCountDaysHolidays = "";
    this.employeeNationalNumberStatus = true;
    this.employeeNationalNumber = "";
    this.employeeNationalNumberIssuingAuthorityCurrent = "";
    this.employeeNationalNumberIssuingAuthorityAr = "";
    this.employeeNationalNumberIssuingAuthorityEn = "";
    this.employeeNationalNumberIssuingAuthorityUnd = "";
    this.employeeIssuanceDateTimeNationalNumber = "";
    this.employeeIssuanceDateNationalNumber = "";
    this.employeeIssuanceTimeNationalNumber = "";
    this.employeeExpiryDateTimeNationalNumber = "";
    this.employeeExpiryDateNationalNumber = "";
    this.employeeExpiryTimeNationalNumber = "";
    this.employeePassportNumberStatus = true;
    this.employeePassportNumber = "";
    this.employeePassportNumberIssuingAuthorityCurrent = "";
    this.employeePassportNumberIssuingAuthorityAr = "";
    this.employeePassportNumberIssuingAuthorityEn = "";
    this.employeePassportNumberIssuingAuthorityUnd = "";
    this.employeeIssuanceDateTimePassportNumber = "";
    this.employeeIssuanceDatePassportNumber = "";
    this.employeeIssuanceTimePassportNumber = "";
    this.employeeExpiryDateTimePassportNumber = "";
    this.employeeExpiryDatePassportNumber = "";
    this.employeeExpiryTimePassportNumber = "";
    this.employeeAddressStatus = true;
    this.employeeAddressCurrent = "";
    this.employeeAddressAr = "";
    this.employeeAddressEn = "";
    this.employeeAddressUnd = "";
    this.employeeWorkContractActionDatetime = "";
    this.employeeWorkContractActionDate = "";
    this.employeeWorkContractActionTime = "";
    this.employeeWorkContractActionDayNameCurrent = "";
    this.employeeWorkContractTerminationStatus = "";
    this.employeeWorkContractArchiveStatus = "";

    this.sendTo = "";
  }
  fillData(data) {
    this.employeeWorkContractToken = data.employeeWorkContractToken;
    this.employeeWorkContractCode = data.employeeWorkContractCode;
    this.employeeWorkContractNameCurrent = data.employeeWorkContractNameCurrent;
    this.employeeWorkContractNameAr = data.employeeWorkContractNameAr;
    this.employeeWorkContractNameEn = data.employeeWorkContractNameEn;
    this.employeeWorkContractNameUnd = data.employeeWorkContractNameUnd;
    this.contractFormToken = data.contractFormToken;
    this.contractFormData = data.contractFormData;
    this.employeeJobToken = data.employeeJobToken;
    this.employeeJobNameCurrent = data.employeeJobNameCurrent;
    this.employeeSectorToken = data.employeeSectorToken;
    this.employeeSectorNameCurrent = data.employeeSectorNameCurrent;
    this.employeeBranchToken = data.employeeBranchToken;
    this.employeeBranchNameCurrent = data.employeeBranchNameCurrent;
    this.employeeToken = data.employeeToken;
    this.employeeCode = data.employeeCode;
    this.employeeNameCurrent = data.employeeNameCurrent;
    this.employeeImagePath = data.employeeImagePath;
    this.employeeMakeActionCode = data.employeeMakeActionCode;
    this.employeeMakeActionToken = data.employeeMakeActionToken;
    this.employeeMakeActionNameCurrent = data.employeeMakeActionNameCurrent;
    this.employeeMakeActionImagePath = data.employeeMakeActionImagePath;
    this.employeeMakeActionNationalNumber =
      data.employeeMakeActionNationalNumber;
    this.employeeMakeActionNationalNumberIssuingAuthorityCurrent =
      data.employeeMakeActionNationalNumberIssuingAuthorityCurrent;
    this.employeeMakeActionIssuanceDateNationalNumber =
      data.employeeMakeActionIssuanceDateNationalNumber;
    this.employeeMakeActionExpiryDateNationalNumber =
      data.employeeMakeActionExpiryDateNationalNumber;
    this.employeeMakeActionPassportNumber =
      data.employeeMakeActionPassportNumber;
    this.employeeMakeActionPassportNumberIssuingAuthorityCurrent =
      data.employeeMakeActionPassportNumberIssuingAuthorityCurrent;
    this.employeeMakeActionIssuanceDatePassportNumber =
      data.employeeMakeActionIssuanceDatePassportNumber;
    this.employeeMakeActionExpiryDatePassportNumber =
      data.employeeMakeActionExpiryDatePassportNumber;
    this.employeeWorkContractStartDateTime =
      data.employeeWorkContractStartDateTime;
    this.employeeWorkContractStartDate = data.employeeWorkContractStartDate;
    this.employeeWorkContractStartTime = data.employeeWorkContractStartTime;
    this.employeeWorkContractEndDateTime = data.employeeWorkContractEndDateTime;
    this.employeeWorkContractEndDate = data.employeeWorkContractEndDate;
    this.employeeWorkContractEndTime = data.employeeWorkContractEndTime;
    this.employeeWorkContractDuration = data.employeeWorkContractDuration;
    this.employeeWorkContractSalary = data.employeeWorkContractSalary;
    this.employeeWorkContractSalaryWithCarrncy =
      data.employeeWorkContractSalaryWithCarrncy;
    this.employeeWorkContractSalaryText = data.employeeWorkContractSalaryText;
    this.employeeWorkContractCountDaysHolidays =
      data.employeeWorkContractCountDaysHolidays;
    this.employeeNationalNumberStatus = data.employeeNationalNumberStatus;
    this.employeeNationalNumber = data.employeeNationalNumber;
    this.employeeNationalNumberIssuingAuthorityCurrent =
      data.employeeNationalNumberIssuingAuthorityCurrent;
    this.employeeNationalNumberIssuingAuthorityAr =
      data.employeeNationalNumberIssuingAuthorityAr;
    this.employeeNationalNumberIssuingAuthorityEn =
      data.employeeNationalNumberIssuingAuthorityEn;
    this.employeeNationalNumberIssuingAuthorityUnd =
      data.employeeNationalNumberIssuingAuthorityUnd;
    this.employeeIssuanceDateTimeNationalNumber =
      data.employeeIssuanceDateTimeNationalNumber;
    this.employeeIssuanceDateNationalNumber =
      data.employeeIssuanceDateNationalNumber;
    this.employeeIssuanceTimeNationalNumber =
      data.employeeIssuanceTimeNationalNumber;
    this.employeeExpiryDateTimeNationalNumber =
      data.employeeExpiryDateTimeNationalNumber;
    this.employeeExpiryDateNationalNumber =
      data.employeeExpiryDateNationalNumber;
    this.employeeExpiryTimeNationalNumber =
      data.employeeExpiryTimeNationalNumber;
    this.employeePassportNumberStatus = data.employeePassportNumberStatus;
    this.employeePassportNumber = data.employeePassportNumber;
    this.employeePassportNumberIssuingAuthorityCurrent =
      data.employeePassportNumberIssuingAuthorityCurrent;
    this.employeePassportNumberIssuingAuthorityAr =
      data.employeePassportNumberIssuingAuthorityAr;
    this.employeePassportNumberIssuingAuthorityEn =
      data.employeePassportNumberIssuingAuthorityEn;
    this.employeePassportNumberIssuingAuthorityUnd =
      data.employeePassportNumberIssuingAuthorityUnd;
    this.employeeIssuanceDateTimePassportNumber =
      data.employeeIssuanceDateTimePassportNumber;
    this.employeeIssuanceDatePassportNumber =
      data.employeeIssuanceDatePassportNumber;
    this.employeeIssuanceTimePassportNumber =
      data.employeeIssuanceTimePassportNumber;
    this.employeeExpiryDateTimePassportNumber =
      data.employeeExpiryDateTimePassportNumber;
    this.employeeExpiryDatePassportNumber =
      data.employeeExpiryDatePassportNumber;
    this.employeeExpiryTimePassportNumber =
      data.employeeExpiryTimePassportNumber;
    this.employeeAddressStatus = data.employeeAddressStatus;
    this.employeeAddressCurrent = data.employeeAddressCurrent;
    this.employeeAddressAr = data.employeeAddressAr;
    this.employeeAddressEn = data.employeeAddressEn;
    this.employeeAddressUnd = data.employeeAddressUnd;
    this.employeeWorkContractActionDatetime =
      data.employeeWorkContractActionDatetime;
    this.employeeWorkContractActionDate = data.employeeWorkContractActionDate;
    this.employeeWorkContractActionTime = data.employeeWorkContractActionTime;
    this.employeeWorkContractActionDayNameCurrent =
      data.employeeWorkContractActionDayNameCurrent;
    this.employeeWorkContractTerminationStatus =
      data.employeeWorkContractTerminationStatus;
    this.employeeWorkContractArchiveStatus =
      data.employeeWorkContractArchiveStatus;
  }

  async getAllEmployeeWorkContracts(
    language,
    userAuthorizeToken,
    pagination = new PaginationData(),
    filterData = new EmployeeWorkContractsFilter()
  ) {
    try {
      let response = await axios.get(
        `/EmployeeWorkContracts/GetAllEmployeeWorkContracts?language=${language}&userAuthorizeToken=${userAuthorizeToken}&employeeWorkContractArchiveStatus=false&page=${pagination.selfPage}&paginationStatus=true&pageSize=${PAGE_SIZE}&filterStatus=true&employeeToken=${filterData.employeeToken}&textSearch=${filterData.textSearch}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getDialogOfEmployeeWorkContracts(
    language,
    userAuthorizeToken,
    filterData = new EmployeeWorkContractsFilter()
  ) {
    return await axios.get(
      `/EmployeeWorkContracts/GetDialogOfEmployeeWorkContracts?language=${language}&userAuthorizeToken=${userAuthorizeToken}&employeeWorkContractArchiveStatus=false&filterStatus=true&employeeToken=${filterData.employeeToken}&textSearch=${filterData.textSearch}`
    );
  }

  async getEmployeeWorkContractDetails(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/EmployeeWorkContracts/GetEmployeeWorkContractDetails?language=${language}&userAuthorizeToken=${userAuthorizeToken}&employeeWorkContractToken=${this.employeeWorkContractToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async printEmployeeWorkContract(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/EmployeeWorkContracts/PrintEmployeeWorkContract?language=${language}&userAuthorizeToken=${userAuthorizeToken}&filterStatus=true&employeeWorkContractToken=${this.employeeWorkContractToken}&sendTo=${this.sendTo}`
      );
      if (response.data.status == STATUS.SUCCESS) {
        viewFileFromServer(response.data.reportPath);
      }
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async addOrUpdateEmployeeWorkContract(language, userAuthorizeToken) {
    //National Number Data if false
    if (!this.employeeNationalNumberStatus) {
      this.employeeNationalNumberIssuingAuthorityAr = "";
      this.employeeNationalNumberIssuingAuthorityEn = "";
      this.employeeNationalNumberIssuingAuthorityUnd = "";
      this.employeeIssuanceDateNationalNumber = "";
      this.employeeIssuanceTimeNationalNumber = "";
      this.employeeExpiryDateNationalNumber = "";
      this.employeeExpiryTimeNationalNumber = "";
    }
    //Passport Data if false
    if (!this.employeePassportNumberStatus) {
      this.employeePassportNumberIssuingAuthorityAr = "";
      this.employeePassportNumberIssuingAuthorityEn = "";
      this.employeePassportNumberIssuingAuthorityUnd = "";
      this.employeeIssuanceDatePassportNumber = "";
      this.employeeIssuanceTimePassportNumber = "";
      this.employeeExpiryDatePassportNumber = "";
      this.employeeExpiryTimePassportNumber = "";
    }
    //Address Data if false
    if (!this.employeeAddressStatus) {
      this.employeeAddressAr = "";
      this.employeeAddressEn = "";
      this.employeeAddressUnd = "";
    }

    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      employeeWorkContractToken: this.employeeWorkContractToken,
      employeeWorkContractCode: this.employeeWorkContractCode,
      employeeToken: this.employeeToken,
      employeeJobToken: this.employeeJobToken,
      contractFormToken: this.contractFormToken,
      employeeWorkContractStartDate: this.employeeWorkContractStartDate,
      employeeWorkContractStartTime: this.employeeWorkContractStartTime,
      employeeWorkContractEndDate: this.employeeWorkContractEndDate,
      employeeWorkContractEndTime: this.employeeWorkContractEndTime,
      employeeWorkContractSalary: this.employeeWorkContractSalary,
      employeeWorkContractCountDaysHolidays:
        this.employeeWorkContractCountDaysHolidays,
      employeeNationalNumberStatus: this.employeeNationalNumberStatus,
      employeeNationalNumberIssuingAuthorityAr:
        this.employeeNationalNumberIssuingAuthorityAr,
      employeeNationalNumberIssuingAuthorityEn:
        this.employeeNationalNumberIssuingAuthorityEn,
      employeeNationalNumberIssuingAuthorityUnd:
        this.employeeNationalNumberIssuingAuthorityUnd,
      employeeIssuanceDateNationalNumber:
        this.employeeIssuanceDateNationalNumber,
      employeeIssuanceTimeNationalNumber:
        this.employeeIssuanceTimeNationalNumber,
      employeeExpiryDateNationalNumber: this.employeeExpiryDateNationalNumber,
      employeeExpiryTimeNationalNumber: this.employeeExpiryTimeNationalNumber,
      employeePassportNumberStatus: this.employeePassportNumberStatus,
      employeePassportNumberIssuingAuthorityAr:
        this.employeePassportNumberIssuingAuthorityAr,
      employeePassportNumberIssuingAuthorityEn:
        this.employeePassportNumberIssuingAuthorityEn,
      employeePassportNumberIssuingAuthorityUnd:
        this.employeePassportNumberIssuingAuthorityUnd,
      employeeIssuanceDatePassportNumber:
        this.employeeIssuanceDatePassportNumber,
      employeeIssuanceTimePassportNumber:
        this.employeeIssuanceTimePassportNumber,
      employeeExpiryDatePassportNumber: this.employeeExpiryDatePassportNumber,
      employeeExpiryTimePassportNumber: this.employeeExpiryTimePassportNumber,
      employeeAddressStatus: this.employeeAddressStatus,
      employeeAddressAr: this.employeeAddressAr,
      employeeAddressEn: this.employeeAddressEn,
      employeeAddressUnd: this.employeeAddressUnd,
    };

    try {
      if (
        this.employeeWorkContractToken == "" ||
        this.employeeWorkContractToken == undefined
      ) {
        return await axios.post(
          `/EmployeeWorkContracts//AddEmployeeWorkContract`,
          data
        );
      } else {
        return await axios.post(
          `/EmployeeWorkContracts//UpdateEmployeeWorkContract`,
          data
        );
      }
    } catch (e) {
      return this.$t("errorCatch");
    }
  }

  async archiveEmployeeWorkContract(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      employeeWorkContractToken: this.employeeWorkContractToken,
    };

    try {
      return await axios.post(
        `/EmployeeWorkContracts//ArchiveEmployeeWorkContract`,
        data
      );
    } catch (e) {
      return this.$t("errorCatch");
    }
  }
}
