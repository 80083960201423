import { PAGE_SIZE } from "@/utils/constants";
import axios from "axios";
import PaginationData from "./../../../general/PaginationData";
import ContractFormsFilter from "./ContractFormsFilter";

export default class ContractForm {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.contractFormToken = "";
    this.contractFormNameCurrent = "";
    this.contractFormNameEn = "";
    this.contractFormNameAr = "";
    this.contractFormNameUnd = "";
    this.contractFormDescriptionCurrent = "";
    this.contractFormDescriptionEn = "";
    this.contractFormDescriptionAr = "";
    this.contractFormDescriptionUnd = "";
    this.contractFormNotes = "";
    this.contractFormArchiveStatus = "";
  }
  fillData(data) {
    this.contractFormToken = data.contractFormToken;
    this.contractFormNameCurrent = data.contractFormNameCurrent;
    this.contractFormNameEn = data.contractFormNameEn;
    this.contractFormNameAr = data.contractFormNameAr;
    this.contractFormNameUnd = data.contractFormNameUnd;
    this.contractFormDescriptionCurrent = data.contractFormDescriptionCurrent;
    this.contractFormDescriptionEn = data.contractFormDescriptionEn;
    this.contractFormDescriptionAr = data.contractFormDescriptionAr;
    this.contractFormDescriptionUnd = data.contractFormDescriptionUnd;
    this.contractFormNotes = data.contractFormNotes;
    this.contractFormArchiveStatus = data.contractFormArchiveStatus;
  }

  async getAllContractForms(
    language,
    userAuthorizeToken,
    pagination = new PaginationData(),
    filterData = new ContractFormsFilter()
  ) {
    try {
      return await axios.get(
        `/ContractForms/GetAllContractForms?language=${language}&userAuthorizeToken=${userAuthorizeToken}&cityArchiveStatus=false&page=${pagination.selfPage}&paginationStatus=true&pageSize=${PAGE_SIZE}&filterStatus=true&textSearch=${filterData.textSearch}`
      );
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getDialogOfContractForms(
    language,
    userAuthorizeToken,
    filterData = new ContractFormsFilter()
  ) {
    return await axios.get(
      `/ContractForms/GetDialogOfContractForms?language=${language}&userAuthorizeToken=${userAuthorizeToken}&filterStatus=true&textSearch=${filterData.textSearch}`
    );
  }

  async getContractFormDetails(language, userAuthorizeToken) {
    try {
      return await axios.get(
        `/ContractForms/GetContractFormDetails?language=${language}&userAuthorizeToken=${userAuthorizeToken}&contractFormToken=${this.contractFormToken}`
      );
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async addOrUpdateContractForm(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      contractFormToken: this.contractFormToken,
      contractFormNameCurrent: this.contractFormNameCurrent,
      contractFormNameEn: this.contractFormNameEn,
      contractFormNameAr: this.contractFormNameAr,
      contractFormNameUnd: this.contractFormNameUnd,
      contractFormDescriptionCurrent: this.contractFormDescriptionCurrent,
      contractFormDescriptionEn: this.contractFormDescriptionEn,
      contractFormDescriptionAr: this.contractFormDescriptionAr,
      contractFormDescriptionUnd: this.contractFormDescriptionUnd,
      contractFormNotes: this.contractFormNotes,
    };

    try {
      if (this.contractFormToken == "" || this.contractFormToken == undefined) {
        return await axios.post(`/ContractForms//AddContractForm`, data);
      } else {
        return await axios.post(`/ContractForms//UpdateContractForm`, data);
      }
    } catch (e) {
      return this.$t("errorCatch");
    }
  }

  async archiveContractForm(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      contractFormToken: this.contractFormToken,
    };

    try {
      return await axios.post(`/ContractForms//ArchiveContractForm`, data);
    } catch (e) {
      return this.$t("errorCatch");
    }
  }
}
